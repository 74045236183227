import ViewConstants from "../constants/ViewConstants"

function next(state) {
  switch (state.view) {
    case ViewConstants.TIPO_PLANTA:
      if (state.tipo_planta) {
        return Object.assign({}, state, {
          view: ViewConstants.PROVINCIA,
        })
      } else {
        return state
      }

    case ViewConstants.PROVINCIA:
      if (state.provincia) {
        return Object.assign({}, state, {
          view: ViewConstants.COMUNA,
        })
      } else {
        return state
      }

    case ViewConstants.COMUNA:
      if (state.comuna) {
        return Object.assign({}, state, {
          view: ViewConstants.CONSUMO,
        })
      } else {
        return state
      }

    default:
      return state
  }
}

export function updateView(
  state = {
    provincia: null,
    comuna: null,
    consumo: null,
    correo: null,
    tipo_planta: null,
    name: "Usuario",
    view: ViewConstants.TIPO_PLANTA,
  },
  action
) {
  switch (action.type) {
    case ViewConstants.TIPO_PLANTA:
      return Object.assign({}, state, {
        tipo_planta: action.tipo,
      })

    case ViewConstants.COMUNA:
      return Object.assign({}, state, {
        comuna: action.comuna,
      })

    case ViewConstants.PROVINCIA:
      return Object.assign({}, state, {
        provincia: action.provincia,
      })

    case ViewConstants.CONSUMO:
      return Object.assign({}, state, {
        consumo: action.consumo,
        view: ViewConstants.CORREO,
      })

    case ViewConstants.SERVER_ERROR:
      return Object.assign({}, state, {
        view: ViewConstants.SERVER_ERROR,
      })

    case ViewConstants.SUCCESS:
      return Object.assign({}, state, {
        view: ViewConstants.SUCCESS,
        correo: action.correo,
        name: action.nombre,
      })

    case ViewConstants.NEXT:
      return next(state)

    case ViewConstants.LEAVE:
      return Object.assign({}, state, {
        provincia: null,
        comuna: null,
        consumo: null,
        tipo_planta: null,
        correo: null,
        view: ViewConstants.TIPO_PLANTA,
      })

    default:
      return state
  }
}
