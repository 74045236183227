import ViewConstants from "../constants/ViewConstants"

export function next() {
  return {
    type: ViewConstants.NEXT,
  }
}

export function setProvincia(provincia) {
  return {
    type: ViewConstants.PROVINCIA,
    provincia: provincia,
  }
}

export function setComuna(comuna) {
  return {
    type: ViewConstants.COMUNA,
    comuna: comuna,
  }
}

export function setConsumo(consumo) {
  return {
    type: ViewConstants.CONSUMO,
    consumo: consumo,
  }
}

export function setTipo(tipo) {
  return {
    type: ViewConstants.TIPO_PLANTA,
    tipo: tipo,
  }
}

export function leave() {
  return {
    type: ViewConstants.LEAVE,
  }
}

export function success(nombre, correo) {
  return {
    type: ViewConstants.SUCCESS,
    correo: correo,
    nombre: nombre,
  }
}

export function error() {
  return {
    type: ViewConstants.SERVER_ERROR,
  }
}
