import React from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import isEmpty from "lodash/isEmpty"

import PhoneInputField from "../ContactForm/phoneinput"

import API from "@aws-amplify/api"
import { createMiPlanta } from "../../graphql/mutations"

import { success, error, setCorreo } from "../../actions/miplanta"

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email("Dirección de correo inválida")
    .required("Requerido"),
  name: Yup.string().required("Requerido"),
  phone: Yup.string().required("Requerido"),
})

let store
export default class Correo extends React.Component {
  constructor(props) {
    super(props)
    store = this.props.store
  }

  render() {
    return (
      <div id="correo">
        <FormContent />
      </div>
    )
  }
}

const FormContent = props => {
  return (
    <Formik
      initialValues={{
        email: "",
        phone: "",
        name: "",
      }}
      validationSchema={SignupSchema}
      onSubmit={async values => {
        const state = store.getState()

        const consumo = parseFloat(state.consumo)
        // TODO: verificar que sea un numero valido

        const tipo_planta = state.tipo_planta
        const provincia = state.provincia
        const comuna = state.comuna
        const email = values.email
        const name = values.name
        const phone = values.phone

        try {
          await API.graphql({
            query: createMiPlanta,
            variables: {
              input: {
                consumo,
                tipo_planta,
                provincia,
                comuna,
                email,
                phone,
                name,
              },
            },
          })
          store.dispatch(success(name, email))
        } catch (e) {
          store.dispatch(error())
        }
      }}
      {...props}
    >
      {({ errors, status, touched, dirty, isSubmitting }) => (
        <Form>
          <Field name="name">
            {({ field, form: { touched, errors } }) => {
              const hasError =
                errors[field.name] && touched[field.name] ? "hasError" : ""
              return (
                <div className="form-group">
                  <input
                    placeholder="Nombre Completo"
                    type="text"
                    className={hasError}
                    {...field}
                  />
                  <span className={"form-icon fa fa-user " + hasError}></span>
                </div>
              )
            }}
          </Field>
          <p className="hasError">
            <ErrorMessage name="name" />
          </p>

          <Field name="email">
            {({ field, form: { touched, errors } }) => {
              const hasError =
                errors[field.name] && touched[field.name] ? "hasError" : ""
              return (
                <div className="form-group">
                  <input placeholder="Email" className={hasError} {...field} />
                  <span
                    className={"form-icon fa fa-envelope-o " + hasError}
                  ></span>
                </div>
              )
            }}
          </Field>
          <p className="hasError">
            <ErrorMessage name="email" />
          </p>

          <Field
            type="tel"
            name="phone"
            component={PhoneInputField}
            placeholder="Teléfono"
          ></Field>

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting || !isEmpty(errors) || !dirty}
            >
              Enviar
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
