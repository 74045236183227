import React from "react"
import { Link } from "gatsby"

import { next, setComuna } from "../../actions/miplanta.js"

let store
export default class Comunas extends React.Component {
  constructor(props) {
    super(props)
    this.state = { button: true }
    this.setComuna = this.setComuna.bind(this)
    store = this.props.store
  }

  nextClick(e) {
    store.dispatch(next())
  }

  setComuna(e) {
    store.dispatch(setComuna(e.target.value))
    this.setState({ button: false })
  }

  render() {
    let options = radiacion[this.props.provincia]

    return (
      <div>
        <select
          className="form-select form-select-md"
          aria-label="comuna select"
          onChange={this.setComuna}
          style={{ textAlignLast: "center" }}
        >
          <option selected>-- Comuna --</option>
          {options.map(element => (
            <option value={element.value}>{element.label}</option>
          ))}
        </select>
        <button
          className="btn btn-primary"
          disabled={this.state.button}
          onClick={this.nextClick}
        >
          Siguiente
        </button>

        <div className="text-center">
          Si no encuentras tu comuna, consulta <Link to="/contact">aquí</Link>
        </div>
      </div>
    )
  }
}

var radiacion = {
  valparaiso: [
    { value: "valparaiso", label: "Valparaíso" },
    { value: "casablanca", label: "Casablanca" },
    { value: "concon", label: "Con-Cón" },
    { value: "puchuncavi", label: "Puchuncaví" },
    { value: "quintero", label: "Quintero" },
    { value: "vina_del_mar", label: "Viña del Mar" },
  ],

  los_andes: [
    { value: "los_andes", label: "Los Andes" },
    { value: "calle_larga", label: "Calle Larga" },
    { value: "rinconada", label: "Rinconada" },
    { value: "san_esteban", label: "San Esteban" },
  ],

  petorca: [
    { value: "la_ligua", label: "La Ligua" },
    { value: "cabildo", label: "Cabildo" },
    { value: "papudo", label: "Papudo" },
    { value: "petorca", label: "Petorca" },
    { value: "zapallar", label: "Zapallar" },
  ],

  quillota: [
    { value: "quillota", label: "Quillota" },
    { value: "calera", label: "Calera" },
    { value: "hijuelas", label: "Hijuelas" },
    { value: "la_cruz", label: "La Cruz" },
    { value: "nogales", label: "Nogales" },
  ],

  san_antonio: [
    { value: "san_antonio", label: "San Antonio" },
    { value: "algarrobo", label: "Algarrobo" },
    { value: "cartagena", label: "Cartagena" },
    { value: "el_quisco", label: "El Quisco" },
    { value: "el_tabo", label: "El Tabo" },
    { value: "santo_domingo", label: "Santo Domingo" },
  ],

  san_felipe: [
    { value: "san_felipe", label: "San Felipe" },
    { value: "catemu", label: "Catemu" },
    { value: "llaillay", label: "Llaillay" },
    { value: "panquehue", label: "Panquehue" },
    { value: "putaendo", label: "Putaendo" },
    { value: "santa_maria", label: "Santa María" },
  ],

  marga_marga: [
    { value: "limache", label: "Limache" },
    { value: "quilpue", label: "Quilpué" },
    { value: "villa_alemana", label: "Villa Alemana" },
    { value: "olmue", label: "Olmué" },
  ],

  cachapoal: [
    { value: "rancagua", label: "Rancagua" },
    { value: "codegua", label: "Codegua" },
    { value: "coinco", label: "Coinco" },
    { value: "coltauco", label: "Coltauco" },
    { value: "doñihue", label: "Doñihue" },
    { value: "graneros", label: "Graneros" },
    { value: "las_cabras", label: "Las Cabras" },
    { value: "machali", label: "Machalí" },
    { value: "malloa", label: "Malloa" },
    { value: "mostazal", label: "Mostazal" },
    { value: "olivar", label: "Olivar" },
    { value: "peumo", label: "Peumo" },
    { value: "pichidegua", label: "Pichidegua" },
    { value: "quinta_de_tilcoco", label: "Quinta de Tilcoco" },
    { value: "rengo", label: "Rengo" },
    { value: "requínoa", label: "Requínoa" },
    { value: "san_vicente", label: "San Vicente" },
  ],

  cardenal_caro: [
    { value: "pichilemu", label: "Pichilemu" },
    { value: "la_estrella", label: "La Estrella" },
    { value: "litueche", label: "Litueche" },
    { value: "marchihue", label: "Marchihue" },
    { value: "navidad", label: "Navidad" },
    { value: "paredones", label: "Paredones" },
  ],

  santiago: [
    { value: "santiago", label: "Santiago" },
    { value: "cerrillos", label: "Cerrillos" },
    { value: "cerro_navia", label: "Cerro Navia" },
    { value: "conchali", label: "Conchalí" },
    { value: "el_bosque", label: "El Bosque" },
    { value: "estacion_central", label: "Estación Central" },
    { value: "huechuraba", label: "Huechuraba" },
    { value: "independencia", label: "Independencia" },
    { value: "la_cisterna", label: "La Cisterna" },
    { value: "la_florida", label: "La Florida" },
    { value: "la_granja", label: "La Granja" },
    { value: "la_pintana", label: "La Pintana" },
    { value: "la_reina", label: "La Reina" },
    { value: "las_condes", label: "Las Condes" },
    { value: "lo_barnechea", label: "Lo Barnechea" },
    { value: "lo_espejo", label: "Lo Espejo" },
    { value: "lo_prado", label: "Lo Prado" },
    { value: "macul", label: "Macul" },
    { value: "maipu", label: "Maipú" },
    { value: "nunoa", label: "Ñuñoa" },
    { value: "pedro_aguirre_cerda", label: "Pedro Aguirre Cerda" },
    { value: "penalolen", label: "Peñalolén" },
    { value: "providencia", label: "Providencia" },
    { value: "pudahuel", label: "Pudahuel" },
    { value: "quilicura", label: "Quilicura" },
    { value: "quinta_normal", label: "Quinta Normal" },
    { value: "recoleta", label: "Recoleta" },
    { value: "renca", label: "Renca" },
    { value: "san_joaquin", label: "San Joaquín" },
    { value: "san_miguel", label: "San Miguel" },
    { value: "san_ramon", label: "San Ramón" },
    { value: "vitacura", label: "Vitacura" },
  ],

  cordillera: [
    { value: "puente_alto", label: "Puente Alto" },
    { value: "pirque", label: "Pirque" },
    { value: "san_jose_de_maipo", label: "San José de Maipo" },
  ],

  chacabuco: [
    { value: "colina", label: "Colina" },
    { value: "lampa", label: "Lampa" },
    { value: "tiltil", label: "Tiltil" },
  ],

  maipo: [
    { value: "san_bernardo", label: "San Bernardo" },
    { value: "buin ", label: "Buin" },
    { value: "calera_de_tango", label: "Calera de Tango" },
    { value: "paine", label: "Paine" },
  ],

  melipilla: [
    { value: "melipilla", label: "Melipilla" },
    { value: "alhue", label: "Alhué" },
    { value: "curacavi", label: "Curacaví" },
    { value: "maria_pinto", label: "María Pinto" },
    { value: "san_pedro", label: "San Pedro" },
  ],

  talagante: [
    { value: "talagante", label: "Talagante" },
    { value: "el_monte", label: "El Monte" },
    { value: "isla_de_Maipo", label: "Isla de Maipo" },
    { value: "padre_Hurtado", label: "Padre Hurtado" },
    { value: "penaflor", label: "Peñaflor" },
  ],
}
