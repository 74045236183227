import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import isEmpty from 'lodash/isEmpty'

import {setConsumo} from '../../actions/miplanta.js';


let store;
export default class Consumo extends React.Component {

  constructor(props){
    super(props);
    store = this.props.store;
  }


  render() {
    var label = '';
    var placeholder = 0;
    switch (this.props.tipo){
      case "residencial":
        label = "¿Cúanto gastas mensualmente en tu cuenta eléctrica (promedio)?";
        placeholder = 100000;
        var pesoSign = true;
        break;
      case "empresas":
      case "agricultura":
        label = "¿Cúanto fue el último consumo total anual (en kWh)?";
        placeholder = 10000;
        var kWhSign = true;
        break;
      default:
        break;
    }

        return (
            <div id='consumo'>
                <div>{label}</div>
                <FormContent    pesoSign={pesoSign}
                                kWhSign={kWhSign}
                                placeholder={placeholder}
                />
            </div>
        );
  }
}



const SignupSchema = Yup.object().shape({
  consumo: Yup.number()
    .typeError("Debe ser un número, sin comas ni puntos!")
    .integer("Debe ser un número entero!")
    .positive('Debe ser un número positivo!')
    .required('Requerido')
});


const submit = (consumo) =>{
    store.dispatch(setConsumo(consumo));
}

const FormContent = (props) => { return(
  <Formik
    initialValues={{
        consumo: ''
    }}
    validationSchema={SignupSchema}
    onSubmit={values => {
        submit(values.consumo);
    }}
    {...props}>
    {({ errors, touched, dirty, isSubmitting }) => (
      <Form>
        <Field name="consumo">
          {({ field, form: {touched, errors} }) => {
            const hasError = errors[field.name] && touched[field.name] ? 'hasError' : '';
            return (
              <div>
                <div className="form-group">
                  {props.pesoSign ? <div className={"input-group-addon " + hasError}>$</div> : ""}
                  <input
                    className={"form-control " + hasError}
                    placeholder={props.placeholder}

                    {...field} />
                    {props.kWhSign ? <div className={"input-group-addon " + hasError}>kWh</div> : ""}
                </div>
                <p className="hasError"><ErrorMessage name="consumo" /></p>
              </div>
          )}}
        </Field>


        <div className="text-center">
            <button type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting || !isEmpty(errors) || !dirty}
            >Siguiente</button>
        </div>
      </Form>


    )}
  </Formik>
)}
