import React from "react"

import NavBar from "../components/NavBar"
import TipoPlanta from "../components/MiPlanta/Tipo.js"
import Comunas from "../components/MiPlanta/Comunas.js"
import Provincia from "../components/MiPlanta/Provincia.js"
import Consumo from "../components/MiPlanta/Consumo.js"
import Correo from "../components/MiPlanta/Correo.js"
import EmailSuccess from "../components/EmailSuccess"
import Spinner from "../components/Spinner"
import ServerError from "../components/ServerError"
import { leave } from "../actions/miplanta.js"
import store from "../stores/miplanta.js"
import ViewConstants from "../constants/ViewConstants.js"

import Amplify from "@aws-amplify/core"
import awsconfig from "../aws-exports"

Amplify.configure(awsconfig)

import "./miplanta.scss"

export function Head() {
  return (
    <>
      <title>Cotización y precio de instalación paneles solares</title>
      <meta
        name="description"
        content="Determina el precio de instalacion de paneles solares ajustado a tus necesidades"
      />
      <meta
        name="keywords"
        content="cotización paneles solares, cotización placas solares, diseño planta solar, precio paneles solares Chile, precio planta solar"
      />
    </>
  )
}

export default class MiPlanta extends React.Component {
  constructor(props) {
    super(props)
    this._onChange = this._onChange.bind(this)
    this.state = store.getState()
    this.unsubscribe = store.subscribe(this._onChange)
  }

  componentWillUnmount() {
    store.dispatch(leave())
    this.unsubscribe()
  }

  _onChange() {
    this.setState(store.getState())
  }

  render() {
    switch (this.state.view) {
      case ViewConstants.TIPO_PLANTA:
        this.child = (
          <div className="layout">
            <TipoPlanta location={this.props.location} store={store} />
          </div>
        )
        break

      case ViewConstants.PROVINCIA:
        this.child = (
          <div className="layout">
            <Provincia store={store} />
          </div>
        )
        break

      case ViewConstants.COMUNA:
        this.child = (
          <div className="layout">
            <Comunas provincia={this.state.provincia} store={store} />
          </div>
        )
        break

      case ViewConstants.CONSUMO:
        this.child = (
          <div className="layout">
            <Consumo tipo={this.state.tipo_planta} store={store} />
          </div>
        )
        break

      case ViewConstants.CORREO:
        this.child = (
          <div className="layout">
            <Correo store={store} />
          </div>
        )
        break

      case ViewConstants.LOADING:
        this.child = <Spinner />
        break

      case ViewConstants.SUCCESS:
        this.child = (
          <EmailSuccess
            email={this.state.correo}
            name={this.state.name}
            text={
              "El resultado de tu diseño ha sido enviado exitosamente a tu correo: "
            }
          />
        )

        // Google Ads conversion tracking
        //typeof window !== "undefined" &&
        //  typeof window.gtag !== "undefined" &&
        //  window.gtag("event", "conversion", {
        //    send_to: "AW-832693741/zMO0COT14dkDEO3Lh40D",
        //  })

        // Delta Digital Google Ads Campaign
        //typeof window !== "undefined" &&
        //  typeof window.gtag !== "undefined" &&
        //  window.gtag("event", "conversion", {
        //    send_to: "AW-10935866203/SrtoCOXj-N4DENum0N4o",
        //  })
        break

      case ViewConstants.SERVER_ERROR:
        this.child = <ServerError />
        break

      default:
        this.child = ""
        break
    }

    return (
      <>
        <section id="fullscreen">
          <NavBar {...this.props} />

          <section id="miplanta">
            <h1 style={{ color: "#6a6a6a" }}>Mis Paneles Solares</h1>
            <h2 style={{ color: "#6a6a6a" }}>
              Cotiza el precio de instalación
            </h2>

            <div className="container">{this.child}</div>
          </section>
        </section>
      </>
    )
  }
}
