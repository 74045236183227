import React from "react"
import { Link } from "gatsby"

import { next, setProvincia } from "../../actions/miplanta.js"

let options = [
  { value: "valparaiso", label: "Valparaíso" },
  { value: "los_andes", label: "Los Andes" },
  { value: "petorca", label: "Petorca" },
  { value: "quillota", label: "Quillota" },
  { value: "san_antonio", label: "San Antonio" },
  { value: "san_felipe", label: "San Felipe de Aconcagua" },
  { value: "marga_marga", label: "Marga Marga" },
  { value: "cachapoal", label: "Cachapoal" },
  { value: "cardenal_caro", label: "Cardenal Caro" },
  { value: "santiago", label: "Santiago" },
  { value: "cordillera", label: "Cordillera" },
  { value: "chacabuco", label: "Petorca" },
  { value: "maipo", label: "Maipo" },
  { value: "melipilla", label: "Melipilla" },
]

let store
export default class Provincia extends React.Component {
  constructor(props) {
    super(props)
    this.state = { button: true }
    this.setProvincia = this.setProvincia.bind(this)
    store = this.props.store
  }

  nextClick(e) {
    store.dispatch(next())
  }

  setProvincia(e) {
    store.dispatch(setProvincia(e.target.value))
    this.setState({ button: false })
  }

  render() {
    return (
      <div id="provincia">
        <select
          className="form-select form-select-md"
          aria-label="provincia select"
          onChange={this.setProvincia}
          style={{ textAlignLast: "center" }}
        >
          <option selected>-- Provincia --</option>
          {options.map(element => (
            <option value={element.value}>{element.label}</option>
          ))}
        </select>
        <button
          className="btn btn-primary"
          disabled={this.state.button}
          onClick={this.nextClick}
        >
          Siguiente
        </button>

        <div className="text-center">
          Si no encuentras tu provincia, consulta{" "}
          <Link to="/contact">aquí</Link>
        </div>
      </div>
    )
  }
}
