import React from "react"
import queryString from "query-string"

import { next, setTipo } from "../../actions/miplanta"

let options = [
  { value: "residencial", label: "Residencial" },
  { value: "empresas", label: "Empresas" },
  { value: "agricultura", label: "Agricultura" },
]

let store
export default class Provincia extends React.Component {
  constructor(props) {
    super(props)
    this.state = { button: true }
    this.setTipo = this.setTipo.bind(this)
    store = this.props.store
  }

  nextClick(e) {
    store.dispatch(next())
  }

  setTipo(e) {
    this.props.store.dispatch(setTipo(e.target.value))
    this.setState({ button: false })
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search)

    switch (parsed.tipo) {
      case "residencial":
      case "empresas":
      case "agricultura":
        this.props.store.dispatch(setTipo(parsed.tipo))
        this.props.store.dispatch(next())
        break

      default:
        break
    }
  }

  render() {
    return (
      <div id="tipo">
        <select
          className="form-select form-select-md"
          aria-label="tipo select"
          onChange={this.setTipo}
          style={{ textAlignLast: "center" }}
        >
          <option selected>-- elige el tipo de planta --</option>
          {options.map(element => (
            <option value={element.value}>{element.label}</option>
          ))}
        </select>

        <button
          className="btn btn-primary"
          disabled={this.state.button}
          onClick={this.nextClick}
        >
          Siguiente
        </button>
      </div>
    )
  }
}
